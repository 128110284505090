import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import * as BlockContent from "@sanity/block-content-to-react"
import theme from "../shared/theme"
import styled from "@emotion/styled"
import { Fade } from "react-slideshow-image"
import { Body, StyledLink } from "../shared/styledElements"
import BlockRenderer from "../utils/textRenderer.js"
import Layout from "../shared/layout.js"

export const query = graphql`
  query($slug: String) {
    sanityProjectAnalog(slug: { current: { eq: $slug } }) {
      project_headline
      project_category
      alt_text
      description
      preview_image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawProjectText
      _rawPictureGallery(resolveReferences: { maxDepth: 25 })
    }
  }
`

const properties = {
  // duration: 5000,
  // transitionDuration: 1000,
  infinite: true,
  autoplay: false,
  arrows: true
}

const defaultDescription =
  "Analoge Fotografie - Lochkamera, Film und Kollodium Nassplatten Verfahren"

export default ({ data }) => (
  <Layout>
    <Helmet>
      <html lang="de" />
      <title>
        {data
          ? `${data.sanityProjectAnalog.project_headline} - Lumenas Photography`
          : ""}
      </title>
      <meta
        name="description"
        content={
          data.sanityProjectAnalog.description
            ? data.sanityProjectAnalog.description
            : defaultDescription
        }
      ></meta>
    </Helmet>
    <Body>
      <Headline>
        <FirstRow>
          <span>
            {data.sanityProjectAnalog.project_category
              ? data.sanityProjectAnalog.project_category
              : ""}
          </span>{" "}
          <StyledLink to="/">Zur Startseite</StyledLink>
        </FirstRow>
        <h1>
          {data.sanityProjectAnalog.project_headline
            ? data.sanityProjectAnalog.project_headline
            : ""}
        </h1>
      </Headline>
      <ProjectText>
        <div>
          <BlockContent
            blocks={
              data.sanityProjectAnalog._rawProjectText
                ? data.sanityProjectAnalog._rawProjectText
                : ""
            }
            serializers={{ types: { block: BlockRenderer } }}
          />
        </div>
      </ProjectText>
      <Separator />

      <Content>
        <PictureContainerSlide>
          <Fade {...properties}>
            {data.sanityProjectAnalog._rawPictureGallery.map((item, index) =>
              item.image.asset.metadata.dimensions.aspectRatio >= 1.2 ? (
                <div key={index}>
                  <ImageInfo>
                    {data.sanityProjectAnalog.project_headline
                      ? data.sanityProjectAnalog.project_headline
                      : ""}
                  </ImageInfo>
                  <ImgContainer>
                    <img
                      src={item.image.asset.url}
                      style={{
                        margin: "auto",
                        display: "block",
                        width: "100%"
                      }}
                      alt={item.alt_text ? item.alt_text : ""}
                    ></img>
                  </ImgContainer>
                  <ImageSubline>
                    <ImageTitle>{item.image_title}</ImageTitle>
                    <ImageInfo>
                      {index +
                        1 +
                        "/" +
                        data.sanityProjectAnalog._rawPictureGallery.length}
                    </ImageInfo>
                  </ImageSubline>
                </div>
              ) : item.image.asset.metadata.dimensions.aspectRatio <= 1.2 &&
                item.image.asset.metadata.dimensions.aspectRatio >= 0.8 ? (
                <div key={index}>
                  <ImageInfo>
                    {data.sanityProjectAnalog.project_headline
                      ? data.sanityProjectAnalog.project_headline
                      : ""}
                  </ImageInfo>
                  <ImgContainer>
                    <img
                      src={item.image.asset.url}
                      style={{
                        margin: "auto",
                        display: "block",
                        width: "70%"
                      }}
                      alt={item.alt_text ? item.alt_text : ""}
                    ></img>
                  </ImgContainer>
                  <ImageSubline>
                    <ImageTitle>{item.image_title}</ImageTitle>
                    <ImageInfo>
                      {index +
                        1 +
                        "/" +
                        data.sanityProjectAnalog._rawPictureGallery.length}
                    </ImageInfo>
                  </ImageSubline>
                </div>
              ) : (
                <div key={index}>
                  <ImageInfo>
                    {data.sanityProjectAnalog.project_headline
                      ? data.sanityProjectAnalog.project_headline
                      : ""}
                  </ImageInfo>
                  <ImgContainer>
                    <img
                      src={item.image.asset.url}
                      style={{
                        margin: "auto",
                        display: "block",
                        width: "50%"
                      }}
                      alt={item.alt_text ? item.alt_text : ""}
                    ></img>
                  </ImgContainer>
                  <ImageSubline>
                    <ImageTitle>{item.image_title}</ImageTitle>
                    <ImageInfo>
                      {index +
                        1 +
                        "/" +
                        data.sanityProjectAnalog._rawPictureGallery.length}
                    </ImageInfo>
                  </ImageSubline>
                </div>
              )
            )}
          </Fade>
        </PictureContainerSlide>
        <LinkContainer>
          <StyledLink aria-label="zurück zur Startseite" to="/">
            ZUR STARTSEITE
          </StyledLink>
        </LinkContainer>
      </Content>
    </Body>
  </Layout>
)

const Headline = styled.div`
  font-family: ${theme.fontFamilyHeading};
  text-transform: uppercase;
  padding-bottom: ${theme.spaces.standardPadding};

  h1 {
    font-size: calc(2 * ${theme.fontsizes.h1MediumScreen});
  }

  span {
    font-size: ${theme.fontsizes.subBannerMediumScreen};
    font-weight: lighter;
  }

  @media all and (max-width: 768px) {
    h1 {
      font-size: calc(3 * ${theme.fontsizes.h1MediumScreen});
    }

    span {
      font-size: calc(2 * ${theme.fontsizes.subBannerMediumScreen});
    }
  }
`

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ProjectText = styled.div`
  width: calc(100% / 3 * 2);
  margin-left: calc(100% / 3 * 1);
  border-left: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};

  div {
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontsizes.textMediumScreen};
    line-height: ${theme.spaces.textMediumScreenLineHeight};
    text-align: justify;
    padding-left: 1vw;
  }

  @media all and (max-width: 768px) {
    width: calc(100% / 5 * 4);
    margin-left: calc(100% / 5 * 1);

    div {
      font-size: ${theme.fontsizes.textSmallScreen};
      line-height: ${theme.spaces.textSmallScreenLineHeight};
    }
  }
`

const Separator = styled.div`
  height: 0.25vw;
  width: 100%;
  border-bottom: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};
  margin-bottom: ${theme.spaces.standardPadding};
  margin-top: ${theme.spaces.standardPadding};
`

const Content = styled.div`
  padding-top: ${theme.spaces.standardPadding};
  padding-bottom: ${theme.spaces.standardPadding};
`

//Container für Bildaufnahme
const PictureContainerSlide = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 50%;

  @media all and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 32vw;

  @media all and (max-width: 768px) {
    height: 65vw;
  }
`

const ImageSubline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ImageTitle = styled.div`
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontsizes.textMediumScreen};
  line-height: calc(1.5 * ${theme.spaces.textMediumScreenLineHeight});
`

const ImageInfo = styled.div`
  font-family: ${theme.fontFamilyHeading};
  font-size: ${theme.fontsizes.h1MediumScreen};
  font-weight: bold;
  text-transform: uppercase;
`

const LinkContainer = styled.div`
  margin-top: ${theme.spaces.standardBlockTopEnd};
`
